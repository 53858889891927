<template>
  <div>
    <FilterForm search-type="categories" />
    <LoaderIcon v-if="loading" />
    <template v-else>
      <template v-if="!recreationWidgetFilterFormError.hasError && recreationWidgetFilterFormError.showSearchResult">
        <div class="card-columns mt-1" v-if="recreationWidgetCategories.length">
          <b-card no-body class="programs-list category-card" v-for="(activity, index) in recreationWidgetCategories" :key="index">
            <b-card-body>
              <b-card-title class="mb-1 theme-font-bold">
                {{ activity.category }}
              </b-card-title>
              <b-card-text class="mb-1 programs" v-for="(program, pIndex) in activity.programList" :key="pIndex">
                <a class="theme-font-bold" href="javascript:void(0)" role="button" @click="$emit('onShowRecreationClasses', program)">{{ program.name }} </a>
              </b-card-text>
            </b-card-body>
          </b-card>
        </div>
        <div v-else class="no-results text-center">
          <SadComputer />
          <div class="font-16 py-2">{{ translateLanguage('Txt_Recreation_widget_No_results_found') }}</div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LoaderIcon from '../../../assets/svg/loader.svg'
import SadComputer from '../../../assets/svg/sad_computer.svg'
export default {
  name: 'RecreationCategories',
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    ...mapState({
      recreationWidgetCategories: (state) => state.shared.recreationWidgetCategories,
      recreationWidgetFilterFormError: (state) => state.shared.recreationWidgetFilterFormError,
    }),
  },
  components: {
    LoaderIcon,
    SadComputer,
    FilterForm: () => import('./FilterForm.vue'),
  },
}
</script>
<style lang="scss" scoped>
.programs-list {
  background: #31a7d315;
  border: 1px solid #dddddd;
  border-radius: 3px;
  border-left: 5px solid #31a7d3;
  margin-bottom: 1.25rem;
  .card-title,
  .card-text {
    font-size: 15px;
  }
  .no-classes {
    pointer-events: none;
    color: #4e4e4e;
  }
}
.disabled.programs-list {
  background: #f7f7f7;
  border: 1px solid #dddddd;
  border-radius: 3px;
  border-left: 5px solid #dddddd;
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .card-columns {
    column-count: 2;
  }
}
</style>
